import React, { useState } from 'react';
import {
  AiOutlineMenu,
  AiOutlineClose,
  AiFillRobot,
  AiFillSliders,
  AiOutlineWhatsApp,
  AiFillFacebook
} from 'react-icons/ai';
import { Link } from 'react-router-dom';  

const Navbar = () => {
  const [nav, setNav] = useState(false)

  return (
    <div className='max-w-[1640px] mx-auto flex justify-between items-center p-4'>
      {/* Left side */}
      <div className='flex items-center'>
        <div onClick={() => setNav(!nav)} className='cursor-pointer'>
          <AiOutlineMenu size={30} />
        </div>
        <a href='/'>
          <img className='max-h-[80px]' src='https://i.postimg.cc/xdYqn0Vk/logoRexy.jpg' alt='Logo Rexy' />
        </a>
      </div>


      <div className='ml-auto flex justify-between items-center p-4'>
        <a href='https://wa.me/526644954278' className='border-none mr-4'>
          <AiOutlineWhatsApp size={30} />
        </a>

        <a href='https://www.facebook.com/people/RexyToys-Collections/100063485295747/?mibextid=qi2Omg&rdid=8yRR9PNfm3Ov2YFc' className='border-none mr-4'>
          <AiFillFacebook size={30} />
        </a>
      </div>


      {nav ? <div className='bg-black/80 fixed w-full h-screen z-10 top-0 left-0'></div> : ''}

      {/* Modo responsivo */}
      <div className={nav ? 'fixed top-0 left-0 w-[300px] h-screen bg-white z-10 duration-300' : 'fixed top-0 left-[-100%] w-[300px] h-screen bg-white z-10 duration-300'}>
        <AiOutlineClose
          onClick={() => setNav(!nav)}
          size={30}
          className='absolute right-4 top-4 cursor-pointer'
        />
        <h2 className='text-2xl p-4'>
          <a href='/'>
            Rexy <span className='font-bold'>Toys</span>
          </a>
        </h2>

        <nav>
          <ul className='flex flex-col p-4 text-gray-800'>

            <li className='text-xl py-4 flex'>
              <AiFillRobot size={25} className='mr-4' />
              <Link to="/products" className='flex items-center'>
                Productos
              </Link>
            </li>

            <li className='text-xl py-4 flex'>
              <Link to="/about" className='flex items-center'>
                <AiFillSliders size={25} className='mr-4' />
                Nosotros
              </Link>

            </li>

          </ul>
        </nav>

      </div>
    </div>
  );
};

export default Navbar;
