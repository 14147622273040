import React from 'react';
import { AiOutlineWhatsApp, AiFillCaretUp } from 'react-icons/ai';

const IconMsj = () => {

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    }

    return (
        <>
            <div className="fixed bottom-8 right-8 animate-bounce">
                <a href="https://wa.me/526644954278">
                    <AiOutlineWhatsApp size={70} className="text-green-600" />
                </a>
            </div>

            <div>
                <button
                    className="fixed bottom-10 left-10 bg-gray-700 hover:bg-gray-500 text-white px-4 py-2 rounded-xl border-none"
                    onClick={scrollToTop}
                >
                    <AiFillCaretUp />
                </button>
            </div>
        </>

    );
};

export default IconMsj;