import React from 'react'
import useEmblaCarousel from 'embla-carousel-react'
import Autoplay from 'embla-carousel-autoplay'
import { Link } from 'react-router-dom';


export const Banner = () => {

  const [emblaRef] = useEmblaCarousel({ loop: false }, [Autoplay()])

  return (
    <div className='overflow-hidden embla max-w-[1640px] mx-auto p-4' ref={emblaRef}>
      <div className="flex embla__container">

        <div className="flex-none w-full min-w-0 embla__slide relative h-full text-gray-200 max-h-[500px]">
          <img className="rounded-xl h-full w-full object-cover" src="https://images.pexels.com/photos/6342784/pexels-photo-6342784.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="/" />
          <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center">
            <h1 className='px-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold'>Listos <span className='text-orange-500'>para</span></h1>
            <h1 className='px-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold'> <span className='text-orange-500'>sorprenderte</span> de nuevo!</h1>
          </div>
        </div>

        <div className="ml-10 flex-none w-full min-w-0 embla__slide relative h-full text-gray-200 max-h-[500px]">
          <img className="w-full rounded-xl object-cover" src="https://images.pexels.com/photos/6654177/pexels-photo-6654177.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="/" />
          <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center">
            <h1 className='px-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold text-white'>Llevate un comic <span className='text-orange-500'>por $80</span></h1>
            <h1 className='px-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold text-white'> <span className='text-orange-500'>o dos por</span> por $100</h1>
          </div>
          <Link to="/products/comics" className='block w-full h-full'>
            <button className='border-white bg-white text-black mx-2 absolute bottom-4'>Ver más</button>
          </Link>
        </div>

        <div className="ml-10 flex-none w-full min-w-0 embla__slide relative h-full text-gray-200 max-h-[500px]">
          <img className="rounded-xl h-full w-full object-cover" src="https://images.pexels.com/photos/90368/pexels-photo-90368.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1" alt="/" />
          <div className="absolute top-0 left-0 w-full h-full flex flex-col justify-center">
            <h1 className='px-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold'>Próximamente </h1>
            <h1 className='px-4 text-4xl sm:text-5xl md:text-6xl lg:text-7xl font-bold'>en vivos</h1>
          </div>
        </div>

      </div>
    </div>

  )
}

export default Banner;
