import React, { useState } from 'react';
import { data } from '../data/products.js';
import { AiOutlineWhatsApp, AiFillCloseCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const Products = () => {

    const [products, setProducts] = useState(data);

    //perimite hacer zoom
    const [modalImage, setModalImage] = useState(null);

    //perimite hacer zoom

    const openModal = (image) => {
        setModalImage(image);
    };

    //perimite hacer zoom

    const closeModal = () => {
        setModalImage(null);
    };


    //   Filtro por categoria
    const filterType = (category) => {
        setProducts(
            data.filter((item) => {
                return item.category === category;
            })
        );
    };

    const [showAll, setShowAll] = useState(false);

    //   Filtro por precios
    const filterPrice = (minPrice, maxPrice) => {
        setProducts(
            data.filter((item) => {
                return item.price >= minPrice && item.price <= maxPrice;
            })
        );
    };

    const shuffleArray = array => {
        for (let i = array.length - 1; i > 0; i--) {
            const j = Math.floor(Math.random() * (i + 1));
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    };

    const randomProducts = shuffleArray(products);


    return (
        <div className='max-w-[1640px] m-auto px-4 py-12'>
            <h1 className='text-orange-600 font-bold text-4xl text-center'>
                Nuestros productos
            </h1>

            {/* filtro de categorías*/}
            <div className='flex flex-col lg:flex-row justify-between'>
                {/* Fliter Type */}
                <div>
                    <p className='font-bold text-gray-700'>Filtrado por temas</p>
                    <div className='flex justfiy-between flex-wrap'>
                        <button
                            onClick={() => setProducts(data)}
                            className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'
                        >
                            Todo
                        </button>
                        <button
                            onClick={() => filterType('Exclusivo')}
                            className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'
                        >
                            Exclusivo
                        </button>
                        <button
                            onClick={() => filterType('Terror')}
                            className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'
                        >
                            Terror
                        </button>
                        <button
                            onClick={() => filterType('Dragon ball')}
                            className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'
                        >
                            Dragon ball
                        </button>
                        <button
                            onClick={() => filterType('Marvel')}
                            className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'
                        >
                            Marvel
                        </button>
                        <Link to="products/comics">
                            <button className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'>
                                Comics certificados, cartas de colección y más
                            </button>
                        </Link>
                    </div>
                </div>

                {/* Filter Price */}
                <div>
                    <p className='font-bold text-gray-700'>Filtrado por precio</p>
                    <div className='flex justify-between max-w-[700px] w-full'>
                        <button
                            onClick={() => filterPrice(0, 1000)}
                            className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'
                        >
                            Menor 1000$
                        </button>

                        <button
                            onClick={() => filterPrice(1000, 1500)}
                            className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'
                        >
                            1000$ - 1500$
                        </button>
                        <button
                            onClick={() => filterPrice(1500, 2000)}
                            className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'
                        >
                            1500$ - 2000$
                        </button>
                        <button
                            onClick={() => filterPrice(2000, 9999)}
                            className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'
                        >
                            Mayor 2000$
                        </button>
                    </div>
                </div>
            </div>

            {/* Muestra los productos */}
            <div className='grid grid-cols-2 lg:grid-cols-4 gap-6 pt-4'>
                {randomProducts.filter(item => item.quantity >= 1)
                    .slice(0, 12)
                    .map((item, index) => (
                        <div
                            key={index}
                            className='border shadow-lg rounded-lg hover:scale-105 duration-300'
                        >
                            <img
                                src={item.image}
                                alt={item.name}
                                className='w-full h-[200px] object-cover rounded-t-lg'
                                onClick={() => openModal(item.image)}

                            />
                            <div className='flex justify-between px-2 py-4'>
                                <p className='font-bold'>{item.name}</p>
                                <p>
                                    <span className='bg-orange-500 text-white p-1 rounded-full'>
                                        ${item.price}
                                    </span>
                                </p>
                            </div>
                            <div className='object-cover rounded-t-lg flex items-center mb-2 ml-2'>
                                <p className='mr-2'>Click para más información</p>
                                <a href='https://wa.me/526644954278' className='border-none'>
                                    <AiOutlineWhatsApp size={30} />
                                </a>
                            </div>
                        </div>
                    ))}

                {modalImage && (
                    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-75 z-50" onClick={closeModal}>
                        <div className="relative" onClick={(e) => e.stopPropagation()}>
                            <button className="absolute top-4 right-4 text-black text-3xl border-none " onClick={closeModal}>
                                <AiFillCloseCircle size={50} />
                            </button>
                            <img src={modalImage} alt="Full size" className="max-w-full max-h-full" />
                        </div>
                    </div>
                )}


                {!showAll && (
                    <Link to="/products" className='block w-full h-full'>

                        <button
                            className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded border border-blue-500'
                            onClick={() => setShowAll(true)}
                        >
                            Ver más
                        </button>
                    </Link>
                )}

                {products.length === 0 && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" onClick={() => setProducts(data)}>
                        <div className="bg-white p-8 rounded-lg">
                            <p className="text-xl font-bold">No hay productos en existencia</p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    );
};

export default Products;
