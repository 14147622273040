import React from 'react';

const ProductCardScreen = () => {

    return (
        <div className='max-w-[1400px] m-auto py-16 px-4 grid lg:grid-cols-2 gap-4'>

        </div>
    );
};

export default ProductCardScreen;
