import React from 'react';

const AboutScreen = () => {
  return (
    <div className='max-w-[1400px] m-auto py-16 px-4 grid lg:grid-cols-2 gap-4'>
      {/* Left Side */}
      <div className='grid grid-cols-2 grid-rows-4 '>
        <img
          className='row-span-4 object-cover w-full h-full p-2'
          src='https://cdn.pixabay.com/photo/2021/01/29/08/09/doodles-5960094_960_720.jpg'
          alt='/'
        />
        <img
          className='row-span-2 object-cover w-full h-full p-2'
          src='https://cdn.pixabay.com/photo/2016/11/04/11/46/robot-1797548_960_720.png'
          alt='/'
        />
        <img
          className='row-span-2 object-cover w-full h-full p-2'
          src='https://cdn.pixabay.com/photo/2017/07/25/22/54/lego-2539844_960_720.jpg'
          alt='/'
        />

      </div>
      {/* Right Side */}
      <div className='flex flex-col h-full justify-center'>
        <h3 className='text-5xl md:text-6xl font-bold'>Acerca de nosotros</h3>
        <p className='text-2xl py-2'>

        </p>
        <p className='pb-6'>
          Acerca de nosotros
          Rexy Toys Collections es un emprendimiento que nace en 2020, como negocio local, tras la pandemia, nos vimos obligados a mover operaciones a traves de redes sociales y con entregas en puntos especificos.
          <br></br>
          Tenemos participacion activa en eventos regionales de coleccionismo, como lo es Figures Palooza.
          Lo nuestro, es el arte de coleccionar. Pero,¿te preguntaras? y ¿que coleccionamos? principlamente Godzilla!
        </p>

        <p className='text-3xl py-2 font-bold'>
          Envíos
        </p>

        <p className='pb-6'>
          · Hacemos envíos a toda la república mexicana
          <br></br>
          · Se envían los días martes por medio de la paqueteria FedEx
          <br></br>
          · Los envios tiene un costo adicional más el precio del producto

        </p>

        <p className='text-3xl py-2 font-bold'>
          Entregas en Tijuana
        </p>

        <p className='pb-6'>
          · Entregamos los días domingos en el mundo divertido
          <br></br>
          · Se hacen entregas apartir de $200 pesos
          <br></br><br></br>
          Cualquier duda o sugerencia, favor de enviar mensaje por medio de nuestras redes sociales

        </p>

      </div>
    </div>
  );
};

export default AboutScreen;