import React, { useState } from 'react';

const BannerCircle = () => {
    const imageUrls = [
        'https://i.postimg.cc/d378BvNr/spawn-Circle.png',
        'https://i.postimg.cc/d1N2rD6J/avengers-Circle.png',
        'https://i.postimg.cc/cJCfR21Q/deadpool-Circle.png',
        'https://i.postimg.cc/T1gVybdF/flash-Circle.png',
        'https://i.postimg.cc/ZKzPGwjS/spiderman-Circle.png',
        'https://i.postimg.cc/rwQStMTV/x-men-Circle.png',
        'https://i.postimg.cc/W3nTwNN3/batman-Circle.png',
        'https://i.postimg.cc/cJGV6pRL/superman-Circle.png',
        'https://i.postimg.cc/VLS5mtsW-/captain-America-Circle.png',
        'https://i.postimg.cc/jjkPMbMs/hulk-Circle.png',
        'https://i.postimg.cc/Pqq1bxz3/iron-Man-Circle.png',
        'https://i.postimg.cc/W45Zjs2k/wolverine-Circle.png',
        'https://i.postimg.cc/Dym20sq0/venom-Circle.png',
        'https://i.postimg.cc/8PDpG5HX/cuatro-Fantasticos-Circle.png',
        'https://i.postimg.cc/GhQcmCgL/linterna-Verde-Circle.png',
        'https://i.postimg.cc/y60129FS/punisher-Circle.png'
    ];

    const [activeIndex, setActiveIndex] = useState(0);

    return (
        <div className="flex justify-center items-center h-auto mb-5 overflow-hidden">
            <div className="flex animate-scrolling space-x-5">
                {imageUrls.map((imageUrl, index) => (
                    <div
                        key={index}
                        className="w-20 h-20 md:w-24 md:h-24 lg:w-28 lg:h-28 rounded-full cursor-pointer bg-white bg-opacity-25 hover:bg-opacity-50"
                        onClick={() => setActiveIndex(index)}
                    >
                        <img
                            src={imageUrl}
                            alt={`Image ${index}`}
                            className="w-full h-full object-cover rounded-full"
                            onError={(e) => { e.target.src = 'https://via.placeholder.com/150'; }}
                        />
                    </div>
                ))}
            </div>

        </div>
    );
};



export default BannerCircle;
