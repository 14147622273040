import React, { useState } from 'react';
import { data } from '../data/products.js';
import { AiOutlineWhatsApp, AiFillCloseCircle } from 'react-icons/ai';
import { Link } from 'react-router-dom';

const ProductScreen = () => {

    const [products, setProducts] = useState(data);

    const [searchTerm, setSearchTerm] = useState('');

    const [modalImage, setModalImage] = useState(null);

    const openModal = (image) => {
        setModalImage(image);
    };

    const closeModal = () => {
        setModalImage(null);
    };


    // Función para filtrar productos por nombre
    const filterProducts = () => {
        if (!searchTerm) {
            // Si no hay término de búsqueda, mostrar todos los productos
            setProducts(data);
        } else {
            // Filtrar productos por nombre que coincida con el término de búsqueda
            setProducts(data.filter(item =>
                item.name.toLowerCase().includes(searchTerm.toLowerCase())
            ));
        }
    };


    //   Filtro por precios
    const filterBrand = (brand) => {
        setProducts(
            data.filter((item) => {
                return item.brand === brand;
            })
        );
    };

    //   Filtro por tipo de producto
    const filterType = (type) => {
        setProducts(
            data.filter((item) => {
                return item.type === type;
            })
        );
    };



    return (
        <div className='max-w-[1640px] m-auto px-4 py-12'>
            <h1 className='text-orange-600 font-bold text-4xl text-center'>
                Productos en existencia
            </h1>


            <div className='mb-4'>
                <input
                    type='text'
                    placeholder='Buscar producto...'
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className='border p-2 rounded-md w-full'
                />
                <button
                    onClick={filterProducts}
                    className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2'
                >
                    Buscar
                </button>
            </div>

            {/* filtro de categorías*/}
            <div className='flex flex-col lg:flex-row justify-between'>
                {/* Fliter Type */}
                <div>
                    <p className='font-bold text-gray-700'>Filtrado por tipos</p>
                    <div className='flex justfiy-between flex-wrap'>
                        <button
                            onClick={() => setProducts(data)}
                            className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'
                        >
                            Todo
                        </button>
                        <button
                            onClick={() => filterType('Figura')}
                            className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'
                        >
                            Figuras
                        </button>
                        <button
                            onClick={() => filterType('Pin')}
                            className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'
                        >
                            Pin's
                        </button>
                        <Link to="comics">
                            <button className='m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white'>
                                Comics certificados, cartas de colección y más
                            </button>
                        </Link>
                    </div>
                </div>

                {/* Filter Price */}
                <div>
                    <p className='font-bold text-gray-700'>Filtrado por marca</p>
                    <div className='flex justify-between max-w-[800px] w-full'>
                        <button
                            onClick={() => filterBrand('Neca')}
                            className='border-none hover:bg-gray-200 hover:text-white'
                        >
                            <img className='max-h-[20px]' src='https://i.postimg.cc/HnZBnWFm/necaLogo.png' alt='Logo Neca' />
                        </button>

                        <button
                            onClick={() => filterBrand('Bandai')}
                            className='border-none hover:bg-gray-200 hover:text-white'
                        >
                            <img className='max-h-[40px]' src='https://i.postimg.cc/Dy3QTM00/bandai-Logo.png' alt='Logo Bandai' />
                        </button>
                        <button
                            onClick={() => filterBrand('ToyBiz')}
                            className='border-none hover:bg-gray-200 hover:text-white'
                        >
                            <img className='max-h-[50px]' src='https://i.postimg.cc/4xTWW3V8/toybiz-Logo.png' alt='Logo ToyBiz' />
                        </button>
                        <button
                            onClick={() => filterBrand('Hasbro')}
                            className='border-none hover:bg-gray-200 hover:text-white'
                        >
                            <img className='max-h-[60px]' src='https://i.postimg.cc/JzgvKx68/hasbro-Logo.png' alt='Logo Hasbro' />
                        </button>
                        <button className='border-none hover:bg-gray-200 mx-2'>Ver más</button>

                    </div>
                </div>
            </div>
            {/* Muestra todos los productos en un numero (count) */}
            <p className="text-center text-xl font-bold mb-4">Total de productos: {products.filter(item => item.quantity >= 1).length}</p>
            <div className='grid grid-cols-2 lg:grid-cols-4 gap-6 pt-4'>
                {/* Muestra los productos forma visual */}
                {products.filter(item => item.quantity >= 1)
                    .sort((a, b) => b.id - a.id)
                    .map((item, index) =>
                    (
                        <div
                            key={index}
                            className='border shadow-lg rounded-lg hover:scale-105 duration-300'
                        >
                            <img
                                src={item.image}
                                alt={item.name}
                                className='w-full h-[200px] object-cover rounded-t-lg'
                                onClick={() => openModal(item.image)}

                            />
                            <div className='flex justify-between px-2 py-4'>
                                <p className='font-bold'>{item.name}</p>
                                <p>
                                    <span className='bg-orange-500 text-white p-1 rounded-full'>
                                        ${item.price}
                                    </span>
                                </p>
                            </div>
                            <div className='object-cover rounded-t-lg flex items-center mb-2 ml-2'>
                                <p className='mr-2'>Click para más información</p>
                                <a href='https://wa.me/526644954278' className='border-none'>
                                    <AiOutlineWhatsApp size={30} />
                                </a>
                            </div>
                        </div>
                    ))}

                {modalImage && (
                    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-75 z-50" onClick={closeModal}>
                        <div className="relative" onClick={(e) => e.stopPropagation()}>
                            <button className="absolute top-4 right-4 text-black text-3xl border-none " onClick={closeModal}>
                                <AiFillCloseCircle size={50} />
                            </button>
                            <img src={modalImage} alt="Full size" className="max-w-full max-h-full" />
                        </div>
                    </div>
                )}

                {/*  Valida si existe productos y si no, arroja mensaje de no hay productos y se cierra tocando la pantalla   */}
                {products.length === 0 && (
                    <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50" onClick={() => setProducts(data)}>
                        <div className="bg-white p-8 rounded-lg">
                            <p className="text-xl font-bold">No hay productos en existencia</p>
                        </div>
                    </div>
                )}


            </div>
        </div>
    );
};

export default ProductScreen;