import React, { useState } from "react";
import { dataPapel } from "../data/products.js";
import { AiOutlineWhatsApp, AiFillCloseCircle } from "react-icons/ai";
import BannerCircle from "../components/BannerCircle";

const ComicSreen = () => {
    
  const [comics, setComics] = useState(dataPapel);

  //const [selectedLetter, setSelectedLetter] = useState('');

  const [searchTerm, setSearchTerm] = useState("");

  const [modalImage, setModalImage] = useState(null);

  const [mostrarSubmenu, setMostrarSubmenu] = useState(false);

  const toggleSubmenu = () => {
    setMostrarSubmenu(!mostrarSubmenu);
  };

  const openModal = (image) => {
    setModalImage(image);
  };

  const closeModal = () => {
    setModalImage(null);
  };

  //const alphabet = 'abcdefghijklmnopqrstuvwxyz'.split('');

  //   Filtro por tipo de producto
  const filterType = (type) => {
    setComics(
      dataPapel.filter((item) => {
        return item.type === type;
      })
    );
  };

  //   Filtro por tipo de producto
  const filterCharacter = (character) => {
    setComics(
      dataPapel.filter((item) => {
        return item.character === character;
      })
    );
  };

  // Función para filtrar productos por nombre
  const filterComics = () => {
    if (!searchTerm) {
      // Si no hay término de búsqueda, mostrar todos los productos
      setComics(dataPapel);
    } else {
      // Filtrar productos por nombre que coincida con el término de búsqueda
      setComics(
        dataPapel.filter((item) =>
          item.name.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    }
    setSearchTerm(""); // Reiniciar el término de búsqueda
  };

  return (
    <div className="max-w-[1640px] m-auto px-4 py-12">
      <BannerCircle />
      <h1 className="text-orange-600 font-bold text-4xl text-center">
        Comics en existencia
      </h1>

      <div className="mb-4">
        <input
          type="text"
          placeholder="Buscar comic (Nombre del comic, personaje o número del comic) eje: X-Men #1"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          className="border p-2 rounded-md w-full"
        />
        <button
          onClick={() => {
            filterComics();
            setSearchTerm("");
          }} // Reiniciar la búsqueda después de filtrar
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-xl border-none"
        >
          Buscar
        </button>
      </div>
      {/* filtro de categorías*/}
      <div className="flex flex-col lg:flex-row justify-between">
        {/* Fliter Type */}
        <div>
          <p className="font-bold text-gray-700">Filtrado por tipos</p>
          <div className="flex justfiy-between flex-wrap">
            <button
              onClick={() => setComics(dataPapel)}
              className="m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white"
            >
              Todo
            </button>
            <button
              onClick={() => filterType("Pasta blanda")}
              className="m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white"
            >
              Comics general
            </button>
            <button
              onClick={() => filterType("comicsCertificados")}
              className="m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white"
            >
              Comics Certificados
            </button>
            <button
              onClick={() => filterType("cartasCertificadas")}
              className="m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white"
            >
              Cartas Certificados
            </button>
            <button
              onClick={() => filterType("Saga completa")}
              className="m-1 border-orange-600 text-orange-600 hover:bg-orange-600 hover:text-white"
            >
              Series completas
            </button>
          </div>
        </div>
        <div className="">
          <button
            onClick={toggleSubmenu}
            type="button"
            className="inline-flex justify-center border border-gray-300 shadow-sm px-4 py-2 bg-orange-600 text-sm font-bold text-white hover:bg-orange-700 rounded-xl focus:ring-offset-2 focus:ring-offset-gray-100"
            id="options-menu"
          >
            Personajes
          </button>

          {mostrarSubmenu && (
            <div
              className="origin-top-right absolute right-30 mt-15 w-40 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              role="menu"
              aria-orientation="vertical"
              aria-labelledby="options-menu"
            >
              <div className="py-1" role="none">
                <button
                  onClick={() => filterCharacter("Superman")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  Superman
                </button>
                <button
                  onClick={() => filterCharacter("X Men")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  X Men
                </button>
                <button
                  onClick={() => filterCharacter("Aquaman")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  Aquaman
                </button>
                <button
                  onClick={() => filterCharacter("Flash")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  Flash
                </button>
                <button
                  onClick={() => filterCharacter("Simpson")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  Simpson
                </button>
                <button
                  onClick={() => filterCharacter("Mad Max")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  Mad Max
                </button>
                <button
                  onClick={() => filterCharacter("Spawn")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  Spawn
                </button>
                <button
                  onClick={() => filterCharacter("Wolverine")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  Wolverine
                </button>
                <button
                  onClick={() => filterCharacter("Infinity")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  Infinity
                </button>
                <button
                  onClick={() => filterCharacter("BatWing")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  BatWing
                </button>
                <button
                  onClick={() => filterCharacter("Justice League")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  Justice League
                </button>
                <button
                  onClick={() => filterCharacter("X Force")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  X Force
                </button>
                <button
                  onClick={() => filterCharacter("Deathshead")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  Deathshead
                </button>
                <button
                  onClick={() => filterCharacter("Deathlok")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  Deathlok
                </button>
                <button
                  onClick={() => filterCharacter("Spiderman")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  Spiderman
                </button>
                <button
                  onClick={() => filterCharacter("Avengers")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  Avengers
                </button>
                <button
                  onClick={() => filterCharacter("Nick Fury")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  Nick Fury
                </button>
                <button
                  onClick={() => filterCharacter("War Machine")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  War Machine
                </button>
                <button
                  onClick={() => filterCharacter("Legends")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  Legends
                </button>
                <button
                  onClick={() => filterCharacter("Gambit")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  Gambit
                </button>
                <button
                  onClick={() => filterCharacter("Batman")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  Batman
                </button>
                <button
                  onClick={() => filterCharacter("Captain America")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  Cap America
                </button>
                <button
                  onClick={() => filterCharacter("Brigade")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  Brigade
                </button>
                <button
                  onClick={() => filterCharacter("Daredevil")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  Daredevil
                </button>
                <button
                  onClick={() => filterCharacter("Cable")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  Cable
                </button>
                <button
                  onClick={() => filterCharacter("Hulk")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  Hulk
                </button>
                <button
                  onClick={() => filterCharacter("Fantastic Four")}
                  className="m-1 border-none text-black hover:bg-gray-200 hover:text-black"
                >
                  Fantastic Four
                </button>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* Muestra todos los productos en un numero (count) */}
      <p className="text-center text-xl font-bold mb-4">
        Total de productos: {comics.filter((item) => item.quantity >= 1).length}
      </p>
      <div className="grid grid-cols-2 lg:grid-cols-4 gap-6 pt-4">
        {/* Muestra los productos forma visual */}
        {comics
          .filter((item) => item.quantity >= 1 && item.name.toLowerCase())
          .sort((a, b) => b.id - a.id)
          .map((item, index) => (
            <div
              key={index}
              className="border shadow-lg rounded-lg hover:scale-105 duration-300"
            >
              <img
                src={item.image}
                alt={item.name}
                className="w-full h-[450px] object-cover rounded-t-lg cursor-pointer"
                onClick={() => openModal(item.image)}
              />
              <ul className="px-2 py-4">
                <li>Fecha publicada: {item.publicationYear}</li>
                <li>Publicado por: {item.publisher}</li>
                <li>Idioma: {item.language}</li>
                <li>Personaje: {item.character}</li>
              </ul>
              <div className="flex justify-between px-2 py-4">
                <p className="font-bold">{item.name}</p>
                <p>
                  <span className="bg-orange-500 text-white p-1 rounded-full">
                    ${item.price}
                  </span>
                </p>
              </div>
              <div className="object-cover rounded-t-lg flex items-center mb-2 ml-2">
                <p className="mr-2">Click para más información</p>
                <a href="https://wa.me/526644954278" className="border-none">
                  <AiOutlineWhatsApp size={30} />
                </a>
              </div>
            </div>
          ))}


                {/* Modal */}
                {modalImage && (
                    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center bg-black bg-opacity-75 z-50" onClick={closeModal}>
                        <div className="relative" onClick={(e) => e.stopPropagation()}>
                            <button className="absolute top-4 right-4 text-black text-3xl border-none " onClick={closeModal}>
                                <AiFillCloseCircle size={50} />
                            </button>
                            <img src={modalImage} alt="Full size" className="max-w-full max-h-full" />
                        </div>
                    </div>
                )}
                {/*
                {comics.filter(item => item.quantity >= 1 && item.name.toLowerCase()).length === 0 && (
                    <div className="border shadow-lg rounded-lg p-4">
                        <p className="text-center">Producto acabado</p>
                    </div>
                )}*/}

        {/*  Valida si existe productos y si no, arroja mensaje de no hay productos y se cierra tocando la pantalla   */}
        {comics.length === 0 && (
          <div
            className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50"
            onClick={() => setComics(dataPapel)}
          >
            <div className="bg-white p-8 rounded-lg">
              <p className="text-xl font-bold">
                No hay productos en existencia
              </p>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default ComicSreen;
