import React from 'react';

const PoliAndConditionsScreen = () => {
    return (
        <div className='max-w-[1400px] m-auto py-16 px-4 grid'>
            <div className='flex flex-col h-full items-center'>
                <h3 className='text-center text-5xl md:text-6xl font-bold'>POLÍTICA DE PRIVACIDAD</h3>
                <p className='text-2xl py-2'>
                </p>
                <p className='pb-6'>
                    El presente Política de Privacidad establece los términos en que usa y protege la información que es proporcionada
                    por sus usuarios al momento de utilizar su sitio web. Esta compañía está comprometida con la seguridad de los datos
                    de sus usuarios. Cuando le pedimos llenar los campos de información personal con la cual usted pueda ser identificado,
                    lo hacemos asegurando que sólo se empleará de acuerdo con los términos de este documento. Sin embargo esta Política
                    de Privacidad puede cambiar con el tiempo o ser actualizada por lo que le recomendamos y enfatizamos revisar
                    continuamente esta página para asegurarse que está de acuerdo con dichos cambios.
                </p>

                <p className='text-2xl py-2'>
                    Uso de la información recogida
                </p>

                <p className='pb-6'>
                    Nuestro sitio web emplea la información con el fin de proporcionar el mejor servicio posible, particularmente para mantener
                    un registro de usuarios, de pedidos en caso que aplique, y mejorar nuestros productos y servicios.  Es posible que sean enviados
                    correos electrónicos periódicamente a través de nuestro sitio con ofertas especiales, nuevos productos y otra información
                    publicitaria que consideremos relevante para usted o que pueda brindarle algún beneficio, estos correos electrónicos serán enviados
                    a la dirección que usted proporcione y podrán ser cancelados en cualquier momento.

                    Rexy Toys Collections está altamente comprometido para cumplir con el compromiso de mantener su información segura.
                    Usamos los sistemas más avanzados
                    y los actualizamos constantemente para asegurarnos que no exista ningún acceso no autorizado.
                </p>

                <p className='text-2xl py-2'>
                    Cookies
                </p>

                <p className='pb-6'>
                    Las cookies son archivos que se almacenan en tu ordenador para facilitar la navegación web. Permiten reconocerte y personalizar
                    tu experiencia en los sitios web. Nuestro sitio utiliza cookies para analizar qué páginas visitas, pero esta información se
                    elimina después. Puedes eliminar las cookies en cualquier momento, y aunque no acceden a tu información personal sin tu
                    consentimiento, la mayoría de los navegadores las aceptan automáticamente para mejorar el servicio web. Sin embargo, puedes
                    optar por no aceptarlas en la configuración de tu ordenador, aunque esto puede afectar algunos servicios del sitio.
                </p>

                <p className='text-2xl py-2'>
                    Enlaces a Terceros
                </p>

                <p className='pb-6'>
                    Este sitio web pudiera contener en laces a otros sitios que pudieran ser de su interés. Una vez que usted de clic en estos
                    enlaces y abandone nuestra página, ya no tenemos control sobre al sitio al que es redirigido y por lo tanto no somos responsables
                    de los términos o privacidad ni de la protección de sus datos en esos otros sitios terceros. Dichos sitios están sujetos a sus
                    propias políticas de privacidad por lo cual es recomendable que los consulte para confirmar que usted está de acuerdo con estas.
                </p>
                
            </div>
        </div>


    );
};

export default PoliAndConditionsScreen;