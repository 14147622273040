import React from 'react'
import Banner from '../components/Banner';
import Subanner from '../components/Subanner';
import Products from '../components/Products';

function Home() {
    return (
        <div>
            <Banner />
            <Subanner />
            <Products />
        </div>
    );
}

export default Home;
