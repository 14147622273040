/*

#     #          #            #            ###            ###                   #    #####  
#     # #    #  ##   ######  ##   #       #   #  #####   #   #   ####  #    #  ##   #     # 
#     # #    # # #       #  # #   #      #     # #    # #     # #    # #    # # #         # 
####### #    #   #      #     #   #      #     # #    # #     # #      ######   #    #####  
#     # #    #   #     #      #   #      #     # #####  #     # #      #    #   #   #       
#     # #    #   #    #       #   #       #   #  #       #   #  #    # #    #   #   #       
#     #  ####  ##### ###### ##### ######   ###   #        ###    ####  #    # ##### ####### 

*/


import React from 'react';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import Home from './screens/Home';
import AboutScreen from './screens/AboutScreen';
import ProductScreen from './screens/ProductScreen';
import PoliAndConditionsScreen from './screens/PoliAndConditionsScreen';
import IconMsj from './components/IconMsj';
import ProductCardScreen from './screens/ProductCardScreen';
import ComicSreen from './screens/ComicScreen';

import { Routes, Route } from 'react-router-dom';

function App() {
  return (
      <div>
        <Navbar />
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='/about' element={<AboutScreen />} />
          <Route path='/products' element={<ProductScreen />} />
          <Route path='/PoliAndConditions' element={<PoliAndConditionsScreen />} />
          <Route path='/productCard' element={<ProductCardScreen />} />
          <Route path='/products/comics' element={<ComicSreen />} />

        </Routes>
        <IconMsj />
        <Footer />

      </div>
  );
}

export default App;
