import React from 'react';
import { Link } from 'react-router-dom';

const Subanner = () => {
  return (
    <div className='max-w-[1640px] mx-auto p-4 py-12 grid md:grid-cols-2 gap-20'>
      {/* Card */}
      <div className='rounded-xl relative'>
        {/* Overlay */}
        <div className='absolute w-full h-full rounded-xl text-white'>
          <p className='font-bold text-2xl px-2 pt-4'>Nuestros Productos</p>
          <Link to="/products" className='block w-full h-full'>
            <button className='border-white bg-white text-black mx-2 absolute bottom-4'>Ver más</button>
          </Link>
        </div>
        <img
          className='max-h-[160px] md:max-h-[200px] w-full object-cover rounded-xl'
          src='https://images.pexels.com/photos/3806754/pexels-photo-3806754.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
          alt='/'
        />
      </div>

      {/* Card */}
      <div className='rounded-xl relative'>
        {/* Overlay */}
        <div className='absolute w-full h-full rounded-xl text-white'>
          <p className='font-bold text-2xl px-2 pt-4'>Nosotros</p>
          <Link to="/about" className='block w-full h-full'>
            <button className='border-white bg-white text-black mx-2 absolute bottom-4'>Ver más</button>
          </Link>

        </div>
        <img
          className='max-h-[120px] md:max-h-[200px] w-full object-cover rounded-xl'
          src='https://media.istockphoto.com/id/1280366064/es/foto/por-encima-de-la-vista-superior-de-la-tabla-de-la-trabajadora-de-almac%C3%A9n-o-vendedor-embalaje.jpg?b=1&s=612x612&w=0&k=20&c=4B6B-bVupYDdMuVtpBxAn6PYfqz_ifMhIHXGWeGMLx4='
          alt=''
        />
      </div>
    </div>
  );
};

export default Subanner;
