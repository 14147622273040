export const data = [
  {
    //Figures
    id: 1,
    name: "Ghostbusters",
    category: "Exclusivo",
    brand: "Diamond Select",
    type: "Figura",
    image: "https://i.postimg.cc/WzcGPB8X/1a.jpg",
    price: 1000,
    description: "Figura nueva, caja un poco maltrada",
    new: "Si",
    quantity: 1,
  },
  {
    id: 2,
    name: "Model kit Jason Friday 13th",
    category: "Terror",
    brand: "Screamin",
    type: "Figura",
    image: "https://i.postimg.cc/PqcbSpwG/2a.jpg",
    price: 2500,
    description: "Modelo completo, ",
    new: "SI",
    quantity: 1,
  },
  {
    id: 3,
    name: "Goku damaged ss3 battle",
    category: "Dragon ball",
    brand: "FUNimation",
    type: "Figura",
    image: "https://i.postimg.cc/k40cg6JN/3a-3.jpg",
    price: 1700,
    description: "Figura completa y nueva, caja en buenas condiciones ",
    new: "Si",
    quantity: 1,
  },
  {
    id: 4,
    name: "Funko pin Venom",
    category: "Funko",
    brand: "Funko",
    type: "Pin",
    image: "https://i.postimg.cc/Ss2d47hN/4a-1.jpg",
    price: 150,
    description: "Figura completa y nueva, caja en buenas condiciones ",
    new: "Si",
    quantity: 1,
  },
  {
    id: 5,
    name: "Figura Thanos",
    category: "Marvel",
    brand: "Disney",
    type: "Figura",
    image: "https://i.postimg.cc/mrrVXWp9/5a.jpg",
    price: 350,
    description: "Figura completa y nueva, caja en buenas condiciones ",
    new: "Si",
    quantity: 1,
  },
  {
    id: 6,
    name: "Bakugan set",
    category: "Bakugan",
    brand: "Spin master",
    type: "Figura",
    image: "https://i.postimg.cc/yYFfWPSD/6a.jpg",
    price: 200,
    description: "Figura completa y nueva, caja en buenas condiciones ",
    new: "Si",
    quantity: 1,
  },
  {
    id: 7,
    name: "Figura Wolverine 10 pulgadas",
    category: "Marvel",
    brand: "ToyBiz",
    type: "Figura",
    image: "https://i.postimg.cc/1zR7kqXf/7a.jpg",
    price: 1000,
    description: "Figura completa y nueva, caja en buenas condiciones ",
    new: "Si",
    quantity: 0,
  },
  {
    id: 8,
    name: "Bakugan Geogan",
    category: "Bakugan",
    brand: "Spin master",
    type: "Figura",
    image: "https://i.postimg.cc/66FS8qjm/8a-1.jpg",
    price: 150,
    description: "Figura completa y nueva, caja en buenas condiciones ",
    new: "Si",
    quantity: 1,
  },
  {
    id: 9,
    name: "Figura Kong sh monsterarts",
    category: "Kaijus",
    brand: "Bandai",
    type: "Figura",
    image: "https://i.postimg.cc/8s8hNqbZ/9a.jpg",
    price: 1500,
    description: "Figura completa y original, caja en buenas condiciones ",
    new: "No",
    quantity: 0,
  },
  {
    id: 10,
    name: "Voltron armable",
    category: "Voltron",
    brand: "Bandai ",
    type: "Figura",
    image: "https://i.postimg.cc/ZqJx9zzg/10a.jpg",
    price: 1000,
    description: "Figura completa y original, caja un poco maltrada",
    new: "Si",
    quantity: 0,
  },
  {
    id: 11,
    name: "Figura Trunks 3 pulgadas",
    category: "Dragon ball",
    brand: "Bandai ",
    type: "Figura",
    image: "https://i.postimg.cc/SQPdgPc2/11a.jpg",
    price: 200,
    description: "Figura completa, nueva y original, caja un poco maltrada",
    new: "Si",
    quantity: 1,
  },
  {
    id: 12,
    name: "Figura King Kong",
    category: "Kaijus",
    brand: "Neca",
    type: "Figura",
    image: "https://i.postimg.cc/mkPwb3Wg/12a.jpg",
    price: 700,
    description:
      "Figura completa, nueva y original, una parte de la caja parte trasera esta rota ",
    new: "Si",
    quantity: 0,
  },
  {
    id: 13,
    name: "Set construccion GIJOE Ninja",
    category: "Construccion",
    brand: "Hasbro",
    type: "Figura",
    image: "https://i.postimg.cc/mrSVV2SB/13a.jpg",
    price: 200,
    description: "Figura completa y original, caja un poco maltratada",
    new: "Si",
    quantity: 0,
  },
  {
    id: 14,
    name: "Set construccion GIJOE Cobra HISS",
    category: "Construccion",
    brand: "Hasbro",
    type: "Figura",
    image: "https://i.postimg.cc/K8B0k5kK/14a-1.jpg",
    price: 200,
    description: "Figura completa y original, caja un poco maltratada",
    new: "Si",
    quantity: 0,
  },
  {
    id: 15,
    name: "Dispensador M&MS",
    category: "Entretenimineto",
    brand: "M&MS",
    type: "Figura",
    image: "https://i.postimg.cc/Yq4nk7tz/15a.jpg",
    price: 250,
    description: "Figura completa, nueva y original, caja un poco maltratada",
    new: "Si",
    quantity: 1,
  },
  {
    id: 16,
    name: "Lonchera Jurassic world",
    category: "Entretenimineto",
    brand: "Universal Studios",
    type: "Figura",
    image: "https://i.postimg.cc/bv0VMRqX/17a.jpg",
    price: 200,
    description: "Figura completa, nueva y original",
    new: "Si",
    quantity: 1,
  },
  {
    id: 17,
    name: "Figura Halo Mantis",
    category: "Halo",
    brand: "XBOX",
    type: "Figura",
    image: "https://i.postimg.cc/jS7F6b9S/18a.jpg",
    price: 350,
    description: "Figura completa, nueva y original, caja un poco maltrada",
    new: "Si",
    quantity: 1,
  },
  {
    id: 18,
    name: "Figura Power Rangers Mighty Morphin Pudgy Pig",
    category: "Power Rangers",
    brand: "Hasbro",
    type: "Figura",
    image: "https://i.postimg.cc/PrNcxZQZ/19a.jpg",
    price: 300,
    description: "Figura completa, nueva y original, caja en buen estado",
    new: "Si",
    quantity: 2,
  },
  {
    id: 19,
    name: "Figura Iron Man",
    category: "Marvel",
    brand: "ToyBiz",
    type: "Figura",
    image: "https://i.postimg.cc/kXGWr89w/21a.jpg",
    price: 1000,
    description: "Figura completa, nueva y original, caja en buen estado",
    new: "Si",
    quantity: 0,
  },
  {
    id: 20,
    name: "Kong sh monsterarts exclusive edition",
    category: "Kaijus",
    brand: "Bandai",
    type: "Figura",
    image: "https://i.postimg.cc/XYyFqbrC/20a.jpg",
    price: 2500,
    description: "Figura completa, nueva y original, caja en buen estado",
    new: "Si",
    quantity: 1,
  },
  {
    id: 21,
    name: "Elektra",
    category: "Marvel",
    brand: "ToyBiz",
    type: "Figura",
    image: "https://i.postimg.cc/3Jqm3Rxh/22a.jpg",
    price: 550,
    description: "Figura completa, nueva y original, caja en buen estado",
    new: "Si",
    quantity: 1,
  },
  {
    id: 22,
    name: "Lego Harry Potter 31201",
    category: "Construccion",
    brand: "Lego",
    type: "Figura",
    image: "https://i.postimg.cc/09r0hgyx/23a.jpg",
    price: 1800,
    description: "Figura completa, nueva y original, caja en buen estado",
    new: "Si",
    quantity: 1,
  },
  {
    id: 23,
    name: "Guantele infinito Iron Man",
    category: "Marvel",
    brand: "Hasbro",
    type: "Figura",
    image: "https://i.postimg.cc/44BpFwCm/24a.jpg",
    price: 1800,
    description: 'Figura completa y original, caja en buen estado',
    new: 'No',
    quantity: 0,
  },
  {
    id: 24,
    name: "Muñeca porselana",
    category: "Otros",
    brand: "Brinns",
    type: "Figura",
    image: "https://i.postimg.cc/905yHXPb/25a.jpg",
    price: 300,
    description: "Figura completa y original, caja en buen estado",
    new: "No",
    quantity: 1,
  },
  {
    id: 25,
    name: "Comic Venom #1 cgc 9.6",
    category: "comicCertificado",
    brand: "Comic",
    type: "Comics",
    image: "https://i.postimg.cc/L5RQtzzZ/26a.jpg",
    price: 2500,
    description: "Producto en muy buen estado",
    new: "Si",
    quantity: 0,
  },
  {
    id: 26,
    name: "The amazing SpiderMan #375 cgc 9.8",
    category: "comicCertificado",
    brand: "Comic",
    type: "Comics",
    image: "https://i.postimg.cc/tRtMXMjd/27a.jpg",
    price: 3000,
    description: "Producto en muy buen estado",
    new: "Si",
    quantity: 0,
  },
  {
    id: 27,
    name: "SpiderMan #1 cgc 9.6",
    category: "comicCertificado",
    brand: "Comic",
    type: "Comics",
    image: "https://i.postimg.cc/vHYSDtYJ/28a.jpg",
    price: 2500,
    description: "Producto en muy buen estado",
    new: "Si",
    quantity: 0,
  },
  {
    id: 28,
    name: "The amazing SpiderMan #378 cgc 9.8",
    category: "comicCertificado",
    brand: "Comic",
    type: "Comics",
    image: "https://i.postimg.cc/qqLbjzLD/29a.jpg",
    price: 2750,
    description: "Producto en muy buen estado",
    new: "Si",
    quantity: 0,
  },
  {
    id: 29,
    name: "Double Dragon Billy Lee",
    category: "Double Dragon",
    brand: "Tyco",
    type: "Figura",
    image: "https://i.postimg.cc/SN9K7ky8/30a.jpg",
    price: 700,
    description: "Producto en muy buen estado",
    new: "Si",
    quantity: 1,
  },
  {
    id: 30,
    name: "Double Dragon Shadow Master",
    category: "Double Dragon",
    brand: "Tyco",
    type: "Figura",
    image: "https://i.postimg.cc/FF8RL7QN/31a.jpg",
    price: 700,
    description: "Producto en muy buen estado",
    new: "Si",
    quantity: 1,
  },
  {
    id: 31,
    name: "Double Dragon Blaster",
    category: "Double Dragon",
    brand: "Tyco",
    type: "Figura",
    image: "https://i.postimg.cc/hvztYqTk/32a.jpg",
    price: 700,
    description: "Producto en muy buen estado",
    new: "Si",
    quantity: 1,
  },
  {
    id: 32,
    name: "Double Dragon Sickle",
    category: "Double Dragon",
    brand: "Tyco",
    type: "Figura",
    image: "https://i.postimg.cc/wTR1fDk1/34a.jpg",
    price: 700,
    description: "Producto en muy buen estado",
    new: "Si",
    quantity: 1,
  },
  {
    id: 33,
    name: "Double Dragon Vortex",
    category: "Double Dragon",
    brand: "Tyco",
    type: "Figura",
    image: "https://i.postimg.cc/Nj15YYSY/35a.jpg",
    price: 600,
    description: "Producto en muy buen estado",
    new: "Si",
    quantity: 1,
  },
  {
    id: 34,
    name: "Double Dragon Shadow Raven",
    category: "Double Dragon",
    brand: "Tyco",
    type: "Figura",
    image: "https://i.postimg.cc/MTsn4D4Q/36a.jpg",
    price: 1000,
    description: "Producto en muy buen estado",
    new: "Si",
    quantity: 1,
  },
  {
    id: 35,
    name: "Double Dragon Cycle",
    category: "Double Dragon",
    brand: "Tyco",
    type: "Figura",
    image: "https://i.postimg.cc/fWr3f8Y4/37a.jpg",
    price: 1000,
    description: "Producto en muy buen estado",
    new: "Si",
    quantity: 1,
  },
  {
    id: 36,
    name: "Transformer Devastador",
    category: "Transformers",
    brand: "Hasbro",
    type: "Figura",
    image: "https://i.postimg.cc/KcgDwGw2/38a.jpg",
    price: 2500,
    description: "Producto nuevo, caja en buenas condiciones",
    new: "Si",
    quantity: 1,
  },
  {
    id: 37,
    name: "Chucky Tiffany",
    category: "Terror",
    brand: "Spirit",
    type: "Figura",
    image: "https://i.postimg.cc/kG8NbsK4/39a.jpg",
    price: 2500,
    description: "Producto nuevo, caja en buenas condiciones",
    new: "Si",
    quantity: 1,
  },
  {
    id: 38,
    name: "Power Rangers Megazord",
    category: "Power Rangers",
    brand: "Imaginex",
    type: "Figura",
    image: "https://i.postimg.cc/HkT0rQxY/41a.jpg",
    price: 2200,
    description: "Producto nuevo, caja en buenas condiciones",
    new: "Si",
    quantity: 1,
  },
  {
    id: 39,
    name: "Resident Evil Hunter",
    category: "Terror",
    brand: "ToyBiz",
    type: "Figura",
    image: "https://i.postimg.cc/6QmCbghq/43a.jpg",
    price: 1500,
    description: "Producto nuevo, caja en buenas condiciones",
    new: "Si",
    quantity: 0,
  },
  {
    id: 40,
    name: "Naruto sh figuarts Minato",
    category: "Anime",
    brand: "Bandai",
    type: "Figura",
    image: "https://i.postimg.cc/Y0cYMFZw/44a.jpg",
    price: 2000,
    description: "Caja en buenas condiciones",
    new: "Si",
    quantity: 1,
  },
  {
    id: 41,
    name: "Godzilla Baragon",
    category: "Kaijus",
    brand: "Bandai",
    type: "Figura",
    image: "https://i.postimg.cc/HL325Mtg/45a.jpg",
    price: 550,
    description: "Nuevo con etiqueta",
    new: "Si",
    quantity: 1,
  },
  {
    id: 42,
    name: "Kong",
    category: "Kaijus",
    brand: "test",
    type: "Figura",
    image: "https://i.postimg.cc/cLCYjzRV/46a.jpg",
    price: 350,
    description: "Nuevo con etiqueta",
    new: "Si",
    quantity: 1,
  },
  {
    id: 43,
    name: "Silent Hill Mezco",
    category: "Terror",
    brand: "Mezco",
    type: "Figura",
    image: "https://i.postimg.cc/X7ht20RF/47a-1.jpg",
    price: 2500,
    description: "Nuevo, caja en buen estado",
    new: "Si",
    quantity: 1,
  },
  {
    id: 44,
    name: "Astro Boy",
    category: "Figura",
    brand: "Tezuka",
    type: "Figura",
    image: "https://i.postimg.cc/HsvmdGfY/55a.jpg",
    price: 400,
    description: "Nuevo, caja en buen estado",
    new: "Si",
    quantity: 1,
  },
  {
    id: 45,
    name: "Micheal Mayers Neca",
    category: "Figura",
    brand: "Neca",
    type: "Figura",
    image: "https://i.postimg.cc/bJ08hZjW/54a.jpg",
    price: 700,
    description: "Nuevo, caja en buen estado",
    new: "Si",
    quantity: 1,
  },
  {
    id: 46,
    name: "Micheal Mayers Neca",
    category: "Figura",
    brand: "Neca",
    type: "Figura",
    image: "https://i.postimg.cc/WzXvdxz7/53a.jpg",
    price: 700,
    description: "Nuevo, caja en buen estado",
    new: "Si",
    quantity: 1,
  },
  {
    id: 47,
    name: "Alf Neca",
    category: "Figura",
    brand: "Neca",
    type: "Figura",
    image: "https://i.postimg.cc/RVZmkCpp/52a.jpg",
    price: 650,
    description: "Nuevo, caja en buen estado",
    new: "Si",
    quantity: 1,
  },
  {
    id: 48,
    name: "Tortuja Ninja Jones Monstruo Neca",
    category: "Figura",
    brand: "Neca",
    type: "Figura",
    image: "https://i.postimg.cc/nVkZS92Q/51a.jpg",
    price: 650,
    description: "Nuevo, caja en buen estado",
    new: "Si",
    quantity: 1,
  },
  {
    id: 49,
    name: "Tortuja Ninja Miguel Angel Monstruo Neca",
    category: "Figura",
    brand: "Neca",
    type: "Figura",
    image: "https://i.postimg.cc/52HVt7Vw/50a.jpg",
    price: 650,
    description: "Nuevo, caja en buen estado",
    new: "Si",
    quantity: 1,
  },
  {
    id: 50,
    name: "Tortuja Ninja Rafael Monstruo Neca",
    category: "Figura",
    brand: "Neca",
    type: "Figura",
    image: "https://i.postimg.cc/zvJNXqzB/49a.jpg",
    price: 650,
    description: "Nuevo, caja en buen estado",
    new: "Si",
    quantity: 0,
  },
  {
    id: 51,
    name: "Tortuja Ninja Leonardo Monstruo Neca",
    category: "Figura",
    brand: "Neca",
    type: "Figura",
    image: "https://i.postimg.cc/rm2c3S75/48a.jpg",
    price: 650,
    description: "Nuevo, caja en buen estado",
    new: "Si",
    quantity: 1,
  },
  {
    id: 52,
    name: "Resident Evil William Biakin and Sherry",
    category: "Terror",
    brand: "ToyBiz",
    type: "Figura",
    image: "https://i.postimg.cc/Y24KLkWn/56a.jpg",
    price: 3500,
    description: "Nuevo, caja en buen estado",
    new: "Si",
    quantity: 0,
  },
  {
    id: 53,
    name: "Resistance Series 1 Ravager",
    category: "Figura",
    brand: "PlayStation",
    type: "Figura",
    image: "https://i.postimg.cc/XqB8jsXL/57a.jpg",
    price: 650,
    description: "Nuevo, caja en buen estado",
    new: "Si",
    quantity: 1,
  },
  {
    id: 54,
    name: "Godzilla Heisei Biollante",
    category: "Figura",
    brand: "Super7",
    type: "Figura",
    image: "https://i.postimg.cc/pXJDpxtF/55a.jpg",
    price: 1300,
    description: "Nuevo, caja en buen estado",
    new: "Si",
    quantity: 1,
  },
  {
    id: 55,
    name: "Chiken Run Rocky",
    category: "Figura",
    brand: "Playmates",
    type: "Figura",
    image: "https://i.postimg.cc/9QxZYp0X/56a.jpg",
    price: 650,
    description: "Nuevo, caja en buen estado",
    new: "Si",
    quantity: 1,
  },
  {
    id: 56,
    name: "Chiken Run Ginger",
    category: "Figura",
    brand: "Playmates",
    type: "Figura",
    image: "https://i.postimg.cc/bwY1nMzF/57a.jpg",
    price: 650,
    description: "Nuevo, caja en buen estado",
    new: "Si",
    quantity: 1,
  },
];

export const dataPapel = [

  {
    id: 1,
    name: "Spawn #11",
    publicationYear: "1994",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/fTXCjSKT/1.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 2,
    name: "Spawn #45",
    publicationYear: "1994",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/CxgNvMPC/2.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 3,
    name: "Spawn #49",
    publicationYear: "1994",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/3xFZzMVs/3.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 4,
    name: "Rising #0",
    publicationYear: "2018",
    character: "Avengers",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/B6Xc8jb3/4.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 5,
    name: "The New Avengers #4",
    publicationYear: "2012",
    character: "Avengers",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Español",
    description: "Aceptable",
    image: "https://i.postimg.cc/3RVj230v/5.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 6,
    name: "Spawn #42",
    publicationYear: "1999",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Español",
    description: "Aceptable",
    image: "https://i.postimg.cc/1zRKQCZf/6.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 7,
    name: "Justice League of America #0",
    publicationYear: "2006",
    character: "Justice league",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/fbHfTcw5/7.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 8,
    name: "Avengers west coast #65",
    publicationYear: "1990",
    character: "Avengers",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/J0XNqSjd/8.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 9,
    name: "Ravage 2099 #1",
    publicationYear: "1992",
    character: "Ravage",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/Df4Pn4Rd/9.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 10,
    name: "Captain America #389",
    publicationYear: "1991",
    character: "Captain America",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/T3gVg8Ty/10.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 11,
    name: "X Force #72",
    publicationYear: "1997",
    character: "X Force",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/PqM1VmpT/11.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 12,
    name: "Batman detective comics #937",
    publicationYear: "2016",
    character: "Batman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/fLTmGJmX/12.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 13,
    name: "Batman #23",
    publicationYear: "2017",
    character: "Batman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/MHgBf54b/13.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 14,
    name: "Wolverine #107",
    publicationYear: "1996",
    character: "Wolverine",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/SsTCkkfP/14.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 15,
    name: "The Uncanny X Men #353",
    publicationYear: "1998",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/v8KfK8Bg/15.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 16,
    name: "Uncanny X Men #408",
    publicationYear: "2002",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/8c7WC9yz/16.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 17,
    name: "Uncanny X Men #407",
    publicationYear: "2002",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/c48wGqgD/17.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 18,
    name: "Brigade #1",
    publicationYear: "1992",
    character: "Brigade",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/52BzjwmL/18.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 19,
    name: "Supreme #2",
    publicationYear: "1993",
    character: "Supreme",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/bvqtns6M/19.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 20,
    name: "StormWatch #2",
    publicationYear: "1993",
    character: "StormWatch",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/rwXW8h5q/20.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 21,
    name: "StormWatch #3",
    publicationYear: "1993",
    character: "StormWatch",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/wBFsQgZB/21.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 22,
    name: "Union #1",
    publicationYear: "1993",
    character: "Union",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/d3ZkFd17/22.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 23,
    name: "Brigade #10",
    publicationYear: "1993",
    character: "Brigade",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/MTTfsgJm/23.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 24,
    name: "Young X Men #6",
    publicationYear: "2008",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/PxRp0Kts/24.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 25,
    name: "Wolverine Gambit #1",
    publicationYear: "1995",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/nM8shzj3/25.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 26,
    name: "Ultimatum #2 variante",
    publicationYear: "2008",
    character: "Ultimatum",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/nVNTDGqP/26.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 27,
    name: "Wolverine #96",
    publicationYear: "1995",
    character: "Wolverine",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/ZYWVnMvN/27.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 2,
  },
  {
    id: 28,
    name: "Bishop #2",
    publicationYear: "1995",
    character: "Bishop",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/L83Q5G52/28.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 29,
    name: "Wolverine #82",
    publicationYear: "1994",
    character: "Wolverine",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/CxhhHkgj/29.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 30,
    name: "She Hulk #7",
    publicationYear: "1989",
    character: "She Hulk",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/qqH0tCw6/30.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 31,
    name: "Astonishing X Men #22",
    publicationYear: "2007",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/jSMs82xB/31.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 32,
    name: "Uncanny X Men #402",
    publicationYear: "2002",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/XJC47gf0/32.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 33,
    name: "Ultimate Origins #1 variant",
    publicationYear: "2008",
    character: "Ultimate",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/hGhKNnhv/33.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 34,
    name: "The Secret Defenders #9",
    publicationYear: "1993",
    character: "Defenders",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/sxPz5DH6/34.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 35,
    name: "The Question #19",
    publicationYear: "1998",
    character: "Question",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/VNTz8hbR/35.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 36,
    name: "Captain Marvel #4",
    publicationYear: "2000",
    character: "Captain marvel",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/66CtkY6r/36.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 37,
    name: "Betty and Me #19",
    publicationYear: "1969",
    character: "Betty and Me",
    publisher: "Archie Comics",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/SNrqsRMj/37.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 38,
    name: "X-Man #57",
    publicationYear: "1999",
    character: "X Man",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/T33xWxV0/38.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 39,
    name: "Mighty Avengers #1",
    publicationYear: "2013",
    character: "Avengers",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/zX588K39/39.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 40,
    name: "Spawn #47",
    publicationYear: "1996",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/wj4pXrFp/40.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 41,
    name: "Zombie Love #5",
    publicationYear: "1992",
    character: "Zombie",
    publisher: "Zuzupetal",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/1tw1Z3Sm/41.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 42,
    name: "Marvel zombies #4",
    publicationYear: "2009",
    character: "Zombie",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/0N6qcCHJ/42.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 43,
    name: "Spawn The Dark Ages #9",
    publicationYear: "1999",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/hGHRw7HZ/43.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 44,
    name: "Spawn #163",
    publicationYear: "2007",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Español",
    description: "Aceptable",
    image: "https://i.postimg.cc/2yYRjNj4/44.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 45,
    name: "Wolverine #108",
    publicationYear: "1996",
    character: "Wolverine",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/fyv4Jqk1/45.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 46,
    name: "X-Men micronaunts #4",
    publicationYear: "1984",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/1zDvmn8j/46.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 47,
    name: "Generations Free #1",
    publicationYear: "2017",
    character: "Generations",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/7P1rshXX/47.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 48,
    name: "Spawn Curse #28",
    publicationYear: "1999",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/L6HKrSbS/48.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 49,
    name: "Spawn Ventilator #3",
    publicationYear: "1996",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/x18r1RQF/49.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 50,
    name: "Spawn #22",
    publicationYear: "1994",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/13jknq5R/50.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 51,
    name: "Batman detective comics #50",
    publicationYear: "2016",
    character: "Batman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/yYv4KGMZ/51.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 52,
    name: "The Flash #2",
    publicationYear: "2016",
    character: "Flash",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/yN3wF8km/52.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 53,
    name: "Gambit #4",
    publicationYear: "1993",
    character: "Gambit",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/Tw8BQvDZ/53.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 54,
    name: "The amazing X-Men #1",
    publicationYear: "1995",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/G2kV5gP9/54.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 55,
    name: "Wolverine #112",
    publicationYear: "1997",
    character: "Wolverine",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/0jPFRGZn/55.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 56,
    name: "X-Men #1",
    publicationYear: "1991",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/KjfVZKH2/56.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 0,
  },
  {
    id: 57,
    name: "X-treme X-Men #1",
    publicationYear: "2001",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/gcH1y5kT/57.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 58,
    name: "X-Men adventures #14",
    publicationYear: "1993",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/rFy3fJDF/58.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 59,
    name: "Aquaman #25",
    publicationYear: "2005",
    character: "Aquaman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/Kv9VwMT7/59.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 60,
    name: "Eternal Warriror #25",
    publicationYear: "1992",
    character: "Eternal Warriror",
    publisher: "Valiant",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/bwsMhw07/60.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 61,
    name: "Raver #1",
    publicationYear: "1993",
    character: "Raver",
    publisher: "Malibu",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/mgJn2W8t/61.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 62,
    name: "Ghost Rider #1",
    publicationYear: "1992",
    character: "Ghost Rider",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Nuevo",
    image: "https://i.postimg.cc/k5Sp2Ypw/62.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 63,
    name: "Aquaman #7",
    publicationYear: "2003",
    character: "AquaMan",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/1tZYwcxS/63.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 64,
    name: "Escape from wonderland #3",
    publicationYear: "2009",
    character: "Escape from wonderland",
    publisher: "Zenescope",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/Ss71r2qM/64.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 65,
    name: "Mad Max #1",
    publicationYear: "2015",
    character: "Mad Max",
    publisher: "Vertigo",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/FzrWnrsn/65.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 66,
    name: "Darker image #1",
    publicationYear: "1993",
    character: "Darker image",
    publisher: "image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/gjgMCCxc/66.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 67,
    name: "The Sensational SpiderMan #0",
    publicationYear: "1996",
    character: "SpiderMan",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/9XbNw7L3/67.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 68,
    name: "Guardians team-up #001",
    publicationYear: "2015",
    character: "Guardians",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/sD2HYgLM/68.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 69,
    name: "Rocket Raccoon #1 variant",
    publicationYear: "2014",
    character: "Rocket Raccoon",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/j2267LjK/69.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 70,
    name: "Eclipso #1",
    publicationYear: "1992",
    character: "Eclipso",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/cCzRM0K3/70.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 71,
    name: "The Creeper #1",
    publicationYear: "2007",
    character: "Creeper",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/DyWdfJDP/71.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 72,
    name: "Mad Max Fury Road #1 variant",
    publicationYear: "2015",
    character: "Mad Max",
    publisher: "Vertigo",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/NGCkw7N0/72.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 73,
    name: "Uncanny X Men #412",
    publicationYear: "2002",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/X7TKrN6S/73.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 74,
    name: "Gambit #2",
    publicationYear: "1994",
    character: "Gambit",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/ncwR0t80/74.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 75,
    name: "The Avengers #1",
    publicationYear: "1989",
    character: "Avengers",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/4x780FsZ/75.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 76,
    name: "Uncanny X Men #400",
    publicationYear: "2001",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/8z2ygBzv/76.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 77,
    name: "Legends #4",
    publicationYear: "1986",
    character: "Legends",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/B6sMXSDQ/77.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 78,
    name: "The Avengers #281",
    publicationYear: "1987",
    character: "Avengers",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/Hnkz96Bq/78.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 79,
    name: "X Force #74",
    publicationYear: "1997",
    character: "X Force",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/VNPFVfKB/79.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 80,
    name: "War Machine #4",
    publicationYear: "1990",
    character: "War Machine",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/KzNQT6r7/80.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 81,
    name: "Nick Fury SHIELD #45",
    publicationYear: "1989",
    character: "Nick Fury",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/NFC4JRn5/81.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 82,
    name: "The Avengers #1",
    publicationYear: "2015",
    character: "Avengers",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/vBQtGPF4/82.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 83,
    name: "Spawn Curse #26",
    publicationYear: "1999",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/2j02Sf5r/83.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 84,
    name: "Spawn Curse #27",
    publicationYear: "1999",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/BQtN8w8D/84.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 85,
    name: "Spawn Curse #7",
    publicationYear: "1999",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/rFbj5CWH/85.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 86,
    name: "Spawn Curse #29",
    publicationYear: "1999",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/rwrJj0Nw/86.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 87,
    name: "Spawn Curse #8",
    publicationYear: "1999",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/fLBjyxDB/87.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 88,
    name: "Spawn #93",
    publicationYear: "2000",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Portada un poco rota",
    image: "https://i.postimg.cc/zfwkmsMm/88.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 89,
    name: "Web Of Spiderman #120, #54, #121, #55",
    publicationYear: "1995",
    character: "Spiderman",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Saga completa",
    image: "https://i.postimg.cc/htMd9VXm/89.jpg",
    type: "Saga completa",
    price: 450,
    quantity: 1,
  },
  {
    id: 90,
    name: "Deathlok #17",
    publicationYear: "1992",
    character: "Deathlok",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/vB1h1cqn/90.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 91,
    name: "Deathshead #2",
    publicationYear: "1992",
    character: "Deathshead",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/9fg1FGpV/91.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 92,
    name: "X Force #9",
    publicationYear: "1992",
    character: "X Force",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/CKxmgvbm/92.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 93,
    name: "Justice League #14",
    publicationYear: "1998",
    character: "Justice League",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/wjV0nKR1/93.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 94,
    name: "BatWing #1",
    publicationYear: "2011",
    character: "BatWing",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/g2dgMcj9/94.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 95,
    name: "Infinity #46",
    publicationYear: "1984",
    character: "Infinity",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/fTYHjSw-j/95.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 96,
    name: "X-Men #103",
    publicationYear: "1999",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/HWqZy9Ck/96.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 97,
    name: "Wolverine #99",
    publicationYear: "1996",
    character: "Wolverine",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/T2LN1HFs/97.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 98,
    name: "Spawn Curse #18",
    publicationYear: "1999",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/hjwCM9zM/98.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 99,
    name: "Spawn Curse #12",
    publicationYear: "1999",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/J0kpFGj8/99.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 100,
    name: "Spawn Curse #10",
    publicationYear: "1999",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/RFqscPFN/100.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 101,
    name: "Spawn Curse #24",
    publicationYear: "1999",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/8CZXf8T2/101.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 102,
    name: "Superman #167",
    publicationYear: "2001",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/YSryS2BN/102.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 103,
    name: "Superman Action #662",
    publicationYear: "1991",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/dVKxzmWx/103.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 104,
    name: "The Adventures Of Superman #585",
    publicationYear: "2000",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/05bFmNMc/104.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 105,
    name: "Superman y Batman #304",
    publicationYear: "1984",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/rF4HnY94/105.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 106,
    name: "Mad Max Fury Road #2",
    publicationYear: "2015",
    character: "Mad Max",
    publisher: "Vertigo",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/66gShNCw/106.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 107,
    name: "Bart Simpson #98",
    publicationYear: "2015",
    character: "Simpson",
    publisher: "Bongo",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/w3YRwPTF/107.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 108,
    name: "The Flash #4",
    publicationYear: "2016",
    character: "Flash",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/mkHFNRRt/108.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 109,
    name: "Aquaman #24",
    publicationYear: "2005",
    character: "Aquaman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/yd5ZZxMM/109.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 110,
    name: "Aquaman Sword Of Atlantis #46",
    publicationYear: "2006",
    character: "Aquaman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/fLkdymr7/110.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 111,
    name: "Aquaman #18",
    publicationYear: "2003",
    character: "Aquaman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/xd6H9ttT/111.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 112,
    name: "Aquaman #27",
    publicationYear: "1996",
    character: "Aquaman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/FHg3B9SS/112.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 113,
    name: "X-Men #78",
    publicationYear: "1998",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/jdfzy6dZ/113.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 114,
    name: "X-Man #19",
    publicationYear: "1996",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/fyGm6y5c/114.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 2,
  },
  {
    id: 115,
    name: "X-Men #33",
    publicationYear: "1994",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/k5JKrkNp/115.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 116,
    name: "X-Man #45",
    publicationYear: "1999",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/J7vNyYms/116.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 117,
    name: "X-Man #34",
    publicationYear: "1999",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/ydrFyvZr/117.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 118,
    name: "X-Man #32",
    publicationYear: "1999",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/RZzwd3W9/118.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 119,
    name: "X-Man #30",
    publicationYear: "1999",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/L5YjCLk4/119.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 120,
    name: "The Adventures Superman #425",
    publicationYear: "1987",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/DwdMFJHy/120.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 121,
    name: "X-Men #60",
    publicationYear: "1997",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/d0Qdmp4w/121.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 122,
    name: "X-Force #7",
    publicationYear: "1992",
    character: "X Force",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/bw9knp0m/122.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 123,
    name: "X-Force #49",
    publicationYear: "1995",
    character: "X Force",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/bwh1dsnG/123.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 124,
    name: "Superman #687",
    publicationYear: "1993",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/zXWnJnyZ/124.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 125,
    name: "X-Force #62",
    publicationYear: "1997",
    character: "X Force",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/pV3fb0XJ/125.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 126,
    name: "The Increible Hulk #65",
    publicationYear: "2000",
    character: "Hulk",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/Kc1PWXFY/126.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 127,
    name: "The Increible Hulk #73",
    publicationYear: "2000",
    character: "Hulk",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/GtTmxJWc/127.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 128,
    name: "She-Hulk #8",
    publicationYear: "1989",
    character: "She Hulk",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/V666dc40/128.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 129,
    name: "Green Lanterns #50",
    publicationYear: "2018",
    character: "Green Lantern",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/fk4T2SsY/129.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 130,
    name: "Brigade #13",
    publicationYear: "1993",
    character: "Brigade",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/tgDJqgdH/130.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 131,
    name: "Spawn Curse #11",
    publicationYear: "1999",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/d1x0CSSH/131.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 132,
    name: "X-Force #1",
    publicationYear: "1991",
    character: "X Force",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/YStjBFW3/132.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 133,
    name: "Spawn Dark Ages #8",
    publicationYear: "1999",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/nh4MW2Cr/133.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 134,
    name: "Spawn Dark Ages #15",
    publicationYear: "1999",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/T3MpQF2B/134.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 135,
    name: "Spawn Dark Ages #24",
    publicationYear: "1999",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/yYSDmsKF/135.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 136,
    name: "Spawn Curse #25",
    publicationYear: "1999",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/NFZL3P69/136.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 137,
    name: "Green Lantern #0",
    publicationYear: "2012",
    character: "Green Lantern",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/VshS05xf/137.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 138,
    name: "X-Men #38",
    publicationYear: "1994",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/DZjSgzmc/138.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 139,
    name: "X-Men #75",
    publicationYear: "1998",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/76YC0zP6/139.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 140,
    name: "X-Factor #128",
    publicationYear: "1996",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/1zKn8BQ2/140.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 141,
    name: "X-Force #63",
    publicationYear: "1997",
    character: "X Force",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/8CGfk3qL/141.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 142,
    name: "X-Force #48",
    publicationYear: "1996",
    character: "X Force",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/ZK1y20H7/142.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 143,
    name: "Daredevil #03",
    publicationYear: "2016",
    character: "Daredevil",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/PrLwS1Wv/143.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 144,
    name: "Cable #39",
    publicationYear: "1997",
    character: "Cable",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/KjjMSMCV/144.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 145,
    name: "Cable #40",
    publicationYear: "1997",
    character: "Cable",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/hjpd5rZT/145.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 146,
    name: "Totally Awesome Hulk #1",
    publicationYear: "2016",
    character: "Hulk",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/26zWGJGC/146.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 147,
    name: "Wolverine #105",
    publicationYear: "1996",
    character: "Wolverine",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/fWqd789h/147.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 148,
    name: "Wolverine #106",
    publicationYear: "1996",
    character: "Wolverine",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/3NqDKwTw/148.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 149,
    name: "Batman Legends Dark Knight #47",
    publicationYear: "1993",
    character: "Batman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/nLK7vZvd/149.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 150,
    name: "The Increible Hulk #34, #35, #36",
    publicationYear: "2001",
    character: "Hulk",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/T1N8PpQB/150.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 151,
    name: "X-Men #77",
    publicationYear: "1998",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/WbTqGx9z/151.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 152,
    name: "X-Men #2",
    publicationYear: "1986",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/yNMkkGgV/152.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 2,
  },
  {
    id: 153,
    name: "X-Men #39",
    publicationYear: "1994",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/Gp843Rp7/153.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 154,
    name: "X-Men #41",
    publicationYear: "1994",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/0yH6qj9K/154.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 155,
    name: "X-Men #37",
    publicationYear: "1991",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/hjVhx0TV/155.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 156,
    name: "X-Men #161",
    publicationYear: "2004",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/y8NJ5cCz/156.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 157,
    name: "X-Men #88",
    publicationYear: "1999",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/zX7b0pMj/157.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 158,
    name: "The Uncanny X-Men #314",
    publicationYear: "1998",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/C5Y54fsX/158.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 159,
    name: "X-Men Prime #1",
    publicationYear: "2017",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/8cH5XPy5/159.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 160,
    name: "X-Men #168",
    publicationYear: "2005",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/wxc7j06p/160.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 161,
    name: "Superman #76",
    publicationYear: "1993",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/GhXtkt8g/161.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 162,
    name: "Superman #50",
    publicationYear: "1990",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/L69hYLXZ/162.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 163,
    name: "Superman #139",
    publicationYear: "1998",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/8zs5wGLp/163.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 164,
    name: "Superman #138",
    publicationYear: "1998",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/2SJ5Ktx4/164.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 165,
    name: "Superboy #19",
    publicationYear: "1991",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/d0y0P9Wg/165.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 166,
    name: "The Adventures Of Superman #502",
    publicationYear: "1993",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/FRXH9J8z/166.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 167,
    name: "Superman #37",
    publicationYear: "1989",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/4dvNnxR5/167.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 168,
    name: "The Adventures Of Superman #577",
    publicationYear: "2000",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/tJXR6GXH/168.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 169,
    name: "The Adventures Of Superman #576",
    publicationYear: "2000",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/x8tfypCN/169.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 170,
    name: "The Man Of Steel Superman #747",
    publicationYear: "1999",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/CMNxg5NM/170.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 171,
    name: "The Man Of Steel Superman #89",
    publicationYear: "1999",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/GhmhhR7F/171.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 172,
    name: "The Man Of Steel Superman #85",
    publicationYear: "1999",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/t41qW5H7/172.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 173,
    name: "The Man Of Steel Superman #90",
    publicationYear: "1999",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/WbYpdyCQ/173.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 174,
    name: "Iron Man #192",
    publicationYear: "1985",
    character: "Iron Man",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/2SPzwZzG/174.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 175,
    name: "The Legacy Of Superman #1",
    publicationYear: "1993",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/j5TRVYnJ/175.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 176,
    name: "The Adventures Of Superman #501",
    publicationYear: "1993",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/6QnB1pqX/176.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 177,
    name: "The Superman #146",
    publicationYear: "1999",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/wvkgg4D4/177.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 178,
    name: "Superman #165",
    publicationYear: "2001",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/mk2RD3wL/178.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 179,
    name: "Superman #774",
    publicationYear: "2001",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/44Bs0Mfm/179.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 180,
    name: "The Man Of Steel Superman #88",
    publicationYear: "1999",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/1XCSNgCp/180.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 181,
    name: "Superman #141",
    publicationYear: "1999",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/bNrpfTrL/181.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 182,
    name: "The Man Of Steel Superman #81",
    publicationYear: "1998",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/DyNFvXbh/182.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 183,
    name: "Superman #763",
    publicationYear: "2000",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/cL8WrLQn/183.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 184,
    name: "Superman #764",
    publicationYear: "2000",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/LsKpfFT0/184.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 185,
    name: "Superman #140",
    publicationYear: "1998",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/VNqcWZZs/185.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 186,
    name: "Superman #778",
    publicationYear: "2001",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/XJn6HKtf/186.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 187,
    name: "The Man Of Steel Superman #82",
    publicationYear: "1998",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/ZRjt1Bh1/187.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 188,
    name: "Spawn Curse #23",
    publicationYear: "1999",
    character: "Spawn",
    publisher: "Image",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/WzdhfPkc/188.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 189,
    name: "Superman #850",
    publicationYear: "2007",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/QtZ3wrXz/189.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 190,
    name: "The Lethal Foes Of Spiderman #2",
    publicationYear: "1993",
    character: "Spiderman",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/k4rZGX8J/190.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 191,
    name: "Spider Girl #48",
    publicationYear: "2002",
    character: "Spiderman",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/5yXnSh87/191.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 192,
    name: "The Spectacular Spiderman #213",
    publicationYear: "1994",
    character: "Spiderman",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/d0n6PsBN/192.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 193,
    name: "Spider Woman #49",
    publicationYear: "1983",
    character: "Spiderman",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/44jrFPn7/193.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 194,
    name: "The Spectacular Spiderman #220",
    publicationYear: "1995",
    character: "Spiderman",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/KYXjGB9R/194.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 195,
    name: "Fantastic Four #333",
    publicationYear: "1989",
    character: "Fantastic Four",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/s21kNNPW/195.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 196,
    name: "Fantastic Four #287",
    publicationYear: "1986",
    character: "Fantastic Four",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/hPdhH2rH/196.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 197,
    name: "Fantastic Four Big Town #3",
    publicationYear: "2001",
    character: "Fantastic Four",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/13Q14XrW/197.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 198,
    name: "Fantastic Four Unlimited #1",
    publicationYear: "1993",
    character: "Fantastic Four",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/sg4dWzn3/198.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 199,
    name: "Fantastic Four #341",
    publicationYear: "1990",
    character: "Fantastic Four",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/N0Gh7nVm/199.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 2,
  },
  {
    id: 200,
    name: "Fantastic Four #339",
    publicationYear: "1990",
    character: "Fantastic Four",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/GtWz7Bm6/200.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 201,
    name: "Fantastic Four #340",
    publicationYear: "1990",
    character: "Fantastic Four",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/wxyCVLWf/201.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 202,
    name: "Fantastic Four #352",
    publicationYear: "1990",
    character: "Fantastic Four",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/ZRg1m5qx/202.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 203,
    name: "Fantastic Four #297",
    publicationYear: "1987",
    character: "Fantastic Four",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/Gty6mn8w/203.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 204,
    name: "Superman #145",
    publicationYear: "1987",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/Qtnf7YG8/204.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 205,
    name: "Superman #570",
    publicationYear: "1999",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/9Xsh5JRH/205.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 206,
    name: "Superman #400",
    publicationYear: "1984",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/mZPs50rS/206.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 207,
    name: "Fantastic Four #350",
    publicationYear: "1991",
    character: "Fantastic Four",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/qMndkyyh/207.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 208,
    name: "Fantastic Four #294",
    publicationYear: "1986",
    character: "Fantastic Four",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/76bpj2qL/208.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 209,
    name: "Fantastic Four #349",
    publicationYear: "1991",
    character: "Fantastic Four",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/Vsx3HvpY/209.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 210,
    name: "Fantastic Four #325",
    publicationYear: "1991",
    character: "Fantastic Four",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/qM29f0H2/210.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 211,
    name: "Fantastic Four #347",
    publicationYear: "1991",
    character: "Fantastic Four",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/sgcqXcXX/211.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 212,
    name: "Fantastic Four #1",
    publicationYear: "2004",
    character: "Fantastic Four",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/bw84pMqW/212.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 213,
    name: "Fantastic Four #172",
    publicationYear: "1976",
    character: "Fantastic Four",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/GpmNsy8j/213.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 214,
    name: "Fantastic Four #302",
    publicationYear: "1987",
    character: "Fantastic Four",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/DwNppd2p/214.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 215,
    name: "Fantastic Four #13",
    publicationYear: "1997",
    character: "Fantastic Four",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/VNZh9kXP/215.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 216,
    name: "The Spectacular Spiderman #2",
    publicationYear: "2003",
    character: "Spiderman",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/HxJh61tc/216.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 217,
    name: "The Amazing Spiderman #16",
    publicationYear: "2006",
    character: "Spiderman",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/tTLmfvrr/217.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 218,
    name: "The Increible Hulk Destructor #2",
    publicationYear: "2005",
    character: "Hulk",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/kGKhdNz7/218.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 219,
    name: "The Increible Hulk #42",
    publicationYear: "2000",
    character: "Hulk",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/SQMP8qsH/219.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 220,
    name: "X-Man #44",
    publicationYear: "1998",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/rF2PNbRs/220.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 221,
    name: "X-Men #57",
    publicationYear: "1996",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/m236Xw7w/221.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 222,
    name: "X-Man #36",
    publicationYear: "1997",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/y8vpB8Q3/222.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 223,
    name: "X-Man #5",
    publicationYear: "1995",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/Dw2ptXgK/223.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 224,
    name: "X-Men Havok #27",
    publicationYear: "1989",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/vmTqmwkq/224.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 225,
    name: "Hulk Smash #2",
    publicationYear: "2001",
    character: "Hulk",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/1tNvpc9D/225.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 226,
    name: "The Incredible Hulk #38",
    publicationYear: "2002",
    character: "Hulk",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/1XhJMnt2/226.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 227,
    name: "The Man Of Steel Superman #91",
    publicationYear: "1999",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/gjV47zLJ/227.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 228,
    name: "The Man Of Steel Superman #38",
    publicationYear: "1989",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/RCKPhRpB/228.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 229,
    name: "Superman #78",
    publicationYear: "1993",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/zGt76Pvn/229.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 2,
  },
  {
    id: 230,
    name: "The Man Of Steel Superman #102",
    publicationYear: "2000",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/SN4gMMHz/230.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 231,
    name: "The Adventures Of Superman #501",
    publicationYear: "1993",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/zX3LDJkH/231.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 232,
    name: "Superman #148",
    publicationYear: "1999",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/g2k4MPzz/232.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 233,
    name: "The Man Of Tomorrow Superman #4",
    publicationYear: "1996",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/xTPtydSp/233.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 234,
    name: "Superman #137",
    publicationYear: "1998",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/JhSq4Db0/234.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 235,
    name: "Superman #779",
    publicationYear: "2001",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/63Rh7W00/235.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 236,
    name: "The Spectacular Spiderman #3",
    publicationYear: "2017",
    character: "Spiderman",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/2yD7hs9f/236.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 237,
    name: "The Amazing Spiderman #5",
    publicationYear: "2016",
    character: "Spiderman",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/jqHXtjCq/237.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 238,
    name: "The Amazing Spider Girl #6",
    publicationYear: "2007",
    character: "Spiderman",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/ZKJLRhy8/238.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 239,
    name: "The Amazing Spiderman #518",
    publicationYear: "2005",
    character: "Spiderman",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/SNVfYtWw/239.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 240,
    name: "The Amazing Spider Girl #8",
    publicationYear: "2007",
    character: "Spiderman",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/kgpNf6Ys/240.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 241,
    name: "Untold Tales Of Spiderman #4",
    publicationYear: "1995",
    character: "Spiderman",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/bJkf0YtJ/241.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 242,
    name: "The Adventures Of Superman #453",
    publicationYear: "1989",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/bwVPpBPz/242.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 243,
    name: "Superman #142",
    publicationYear: "1999",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/3JB7bwb0/243.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 244,
    name: "Superman #776",
    publicationYear: "2001",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/SKXhsfQc/244.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 245,
    name: "Tangle Web Spiderman #6",
    publicationYear: "2001",
    character: "Spiderman",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/4NZs5cwT/245.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 246,
    name: "The Man Of Steel Superman #28",
    publicationYear: "1994",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/CxxYyVWP/246.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 247,
    name: "X-Men #19",
    publicationYear: "1993",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/FF0h3pyZ/247.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 248,
    name: "The Uncanny X-Men #336",
    publicationYear: "1996",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/xdWSbNqh/248.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 249,
    name: "X-Men Annual",
    publicationYear: "1997",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/D0hkxVrc/249.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 250,
    name: "X-Men #63",
    publicationYear: "1997",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/j2hV98vY/250.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 251,
    name: "Cable #03",
    publicationYear: "2017",
    character: "Cable",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/T2jXpwxN/251.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 252,
    name: "X-Men #69",
    publicationYear: "1997",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/NfgvTcfs/252.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 253,
    name: "X-Men #17",
    publicationYear: "1992",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/pXqbJ6pD/253.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 254,
    name: "The Increible Hulk #53",
    publicationYear: "2003",
    character: "Hulk",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/K8jXqDMC/254.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 255,
    name: "The Increible Hulk #37",
    publicationYear: "2003",
    character: "Hulk",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/3xcsP0jN/255.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 256,
    name: "Hulk Thing Hard Knocks #4",
    publicationYear: "2004",
    character: "Hulk",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/MGfCxZtD/256.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 257,
    name: "She Hulk #9",
    publicationYear: "2006",
    character: "She Hulk",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/hj9NzCkQ/257.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 258,
    name: "The Increible Hulk #52",
    publicationYear: "2003",
    character: "Hulk",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/wvKSBKGd/258.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 259,
    name: "The Increible Hulk #79",
    publicationYear: "2005",
    character: "Hulk",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/qq4S8srb/259.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 260,
    name: "Planet Hulk #5",
    publicationYear: "2015",
    character: "Hulk",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/j2c9vDds/260.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 261,
    name: "Pokemon Golem Ex CGC 9",
    publicationYear: "2023",
    character: "Golemn",
    publisher: "Pokemon",
    grade: "Si",
    certification: "9",
    language: "Japones",
    description: "Aceptable",
    image: "https://i.postimg.cc/mrDpsf3t/261.jpg",
    type: "cartasCertificadas",
    price: 650,
    quantity: 1,
  },
  {
    id: 262,
    name: "Pokemon Psyduck Ex CGC 9",
    publicationYear: "2023",
    character: "Psyduck",
    publisher: "Pokemon",
    grade: "Si",
    certification: "9",
    language: "Japones",
    description: "Aceptable",
    image: "https://i.postimg.cc/YqKPmP7s/262.jpg",
    type: "cartasCertificadas",
    price: 550,
    quantity: 1,
  },
  {
    id: 263,
    name: "Pokemon Tangela Ex CGC 10",
    publicationYear: "2023",
    character: "Tangela",
    publisher: "Pokemon",
    grade: "Si",
    certification: "10",
    language: "Japones",
    description: "Aceptable",
    image: "https://i.postimg.cc/C1nvRGQ0/263.jpg",
    type: "cartasCertificadas",
    price: 550,
    quantity: 1,
  },
  {
    id: 264,
    name: "Pokemon Alakazam Ex CGC 10",
    publicationYear: "2023",
    character: "Alakazam",
    publisher: "Pokemon",
    grade: "Si",
    certification: "10",
    language: "Japones",
    description: "Aceptable",
    image: "https://i.postimg.cc/3x3btDdj/264.jpg",
    type: "cartasCertificadas",
    price: 800,
    quantity: 1,
  },
  {
    id: 265,
    name: "Pokemon Zapdos Ex CGC 10",
    publicationYear: "2023",
    character: "Zapdos",
    publisher: "Pokemon",
    grade: "Si",
    certification: "10",
    language: "Japones",
    description: "Aceptable",
    image: "https://i.postimg.cc/nLPRVRTT/265.jpg",
    type: "cartasCertificadas",
    price: 800,
    quantity: 1,
  },
  {
    id: 266,
    name: "Moon Knight #7 CGC 9.8",
    publicationYear: "2022",
    character: "Moon Knight",
    publisher: "Marvel",
    grade: "Si",
    certification: "9.8",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/Yq6sNxqq/266.jpg",
    type: "comicsCertificados",
    price: 1800,
    quantity: 1,
  },
  {
    id: 267,
    name: "Ultimate Spiderman #79 CGC 9.8",
    publicationYear: "2005",
    character: "Spiderman",
    publisher: "Marvel",
    grade: "Si",
    certification: "9.8",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/sxFT7Mkh/267.jpg",
    type: "comicsCertificados",
    price: 2200,
    quantity: 1,
  },
  {
    id: 268,
    name: "The Amazing Spiderman #26 CGC 9.6",
    publicationYear: "2001",
    character: "Spiderman",
    publisher: "Marvel",
    grade: "Si",
    certification: "9.6",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/BnN7kQKr/268.jpg",
    type: "comicsCertificados",
    price: 2000,
    quantity: 1,
  },
  {
    id: 269,
    name: "The Amazing Spiderman #359 CGC 9.8",
    publicationYear: "1992",
    character: "Spiderman",
    publisher: "Marvel",
    grade: "Si",
    certification: "9.8",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/NF1p4gtP/269.jpg",
    type: "comicsCertificados",
    price: 2200,
    quantity: 1,
  },
  {
    id: 270,
    name: "X-Men #51",
    publicationYear: "1996",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/VNKFVwNt/270.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 271,
    name: "X-Men Legacy #269",
    publicationYear: "2012",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/rF4QF7h6/271.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 272,
    name: "X-Men #53",
    publicationYear: "1996",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/g2P4w45x/272.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 273,
    name: "X-Men #171",
    publicationYear: "2005",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/9QtbyZ5J/273.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 274,
    name: "X-Men Extraordinary #6",
    publicationYear: "2016",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/x8zy8b3G/274.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 275,
    name: "X-Men #52",
    publicationYear: "1996",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/bYDR3KfG/275.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 276,
    name: "The Uncanny X-Men #323",
    publicationYear: "1995",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/zG5SDvbW/276.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 277,
    name: "X-Men #170",
    publicationYear: "2005",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/qBPxYjp1/277.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 278,
    name: "X-Men #189",
    publicationYear: "2006",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/2Sw-d1Yht/278.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 279,
    name: "Cable #4",
    publicationYear: "2017",
    character: "Cable",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/HLkw5Ntk/279.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 280,
    name: "The Uncanny X-Men #334",
    publicationYear: "1996",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/2594xxHF/280.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 281,
    name: "The Amazing Spiderman #376",
    publicationYear: "1993",
    character: "Spiderman",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/gJw3t6Q9/281.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 282,
    name: "The Spectacular Spiderman #165",
    publicationYear: "1990",
    character: "Spiderman",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/0jDDP19c/282.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 283,
    name: "Ultimate Six #3",
    publicationYear: "2003",
    character: "Spiderman",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/9X7GGZyZ/283.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 284,
    name: "The Amazing Spiderman #2",
    publicationYear: "2015",
    character: "Spiderman",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/Hs1b3ntK/284.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 285,
    name: "Uncanny X-Men Annual #1",
    publicationYear: "2006",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/SNLCP4TK/285.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 286,
    name: "The Increible Hulk #61",
    publicationYear: "2000",
    character: "Hulk",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/CK58dqFZ/286.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 287,
    name: "The Increible Hulk #51",
    publicationYear: "2000",
    character: "Hulk",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/vmKVnZB0/287.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 288,
    name: "Hulk #5",
    publicationYear: "2017",
    character: "Hulk",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/cH88BGt0/288.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 289,
    name: "Batman #8",
    publicationYear: "2017",
    character: "Batman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/1XhVW7D6/289.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 290,
    name: "Batman #11",
    publicationYear: "2017",
    character: "Batman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/hPn7TwDR/290.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 291,
    name: "Batman Detective #4",
    publicationYear: "2012",
    character: "Batman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/Kv53NNjk/291.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 292,
    name: "Batman #12",
    publicationYear: "2017",
    character: "Batman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/mg2z6H1d/292.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 293,
    name: "Aquaman #17",
    publicationYear: "2004",
    character: "Batman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/Gp4BbnQG/293.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 294,
    name: "The Man Of Steel Superman #83",
    publicationYear: "1998",
    character: "Superman",
    publisher: "DC",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/ZR3CyPXq/294.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  {
    id: 295,
    name: "X-Men #86",
    publicationYear: "1997",
    character: "X Men",
    publisher: "Marvel",
    grade: "No",
    certification: "No",
    language: "Inglés",
    description: "Aceptable",
    image: "https://i.postimg.cc/Bb0t5LGB/295.jpg",
    type: "Pasta blanda",
    price: 80,
    quantity: 1,
  },
  
];
