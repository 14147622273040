import React from 'react';
import { Link } from 'react-router-dom';


const Footer = () => {
    return (
        <div className='bg-black'>
            <div className='max-w-[1640px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 text-gray-300'>
                <div>
                    <h1 className='w-full text-3xl font-bold text-green-700'>Rexy Toys</h1>
                    <p className='py-1'>Página elaborada por M.G - Rexy Toys Collection</p>
                    <p className=''>Tijuana Baja California CP:22253</p>
                    <p className=''>© 2024 - Rexy Toys Collections – Rexy Toys. Todos los derechos reservados.</p>
                    <Link to="/PoliAndConditions" className='border-none'>
                        <p className='font-bold'> Políticas de privacidad y seguridad </p>
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Footer;